import React from 'react';
import Layout from '../../../../components/Layout';
import Seo from '../../../../components/SEO';
import SectionIntro from '../../../../components/SectionIntro';
import {useStaticQuery, graphql} from 'gatsby';
import {getImage} from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import {convertToBgImage} from 'gbimage-bridge';
import BackGlossary from '../../../../components/BackGlossary';
import BannerRussel from '../../../../components/BannerRussel';

const GlossaryPageContent = () => {
  return (
    <div className="md:px-5 max-w-[1285px]">
      <div className="mb-8 text-xl font-bold text-left md:text-2xl">Data Security Posture Management (DSPM)</div>
      <p className="mb-12 text-lg text-left">
        DSPM stands for "Data Security Posture Management." It is a term to describe the practice of assessing,
        managing, and improving an organization's overall data security posture. A data security posture refers to the
        collective security measures, strategies, policies, and practices that an organization employs to protect its
        data assets from various threats and vulnerabilities.
      </p>

      <p className="mb-12 text-lg text-left">
        Data Security Posture Management involves a set of processes, tools, and methodologies that help organizations
        gain better visibility into their data security practices and identify areas that need improvement. The goal is
        to ensure that an organization's data assets are adequately protected, and that data security measures are
        aligned with industry best practices and compliance requirements.
      </p>

      <p className="mb-12 text-lg text-left"></p>

      <p className="mb-6 text-lg text-left">Key aspects of Data Security Posture Management include:</p>

      <ul className="mb-12 list-disc text-lgml-12">
        <li className="mb-5">
          Risk Assessment: Evaluating the risks and potential vulnerabilities associated with the organization's data
          assets. This includes assessing both internal and external threats.
        </li>
        <li className="mb-5">
          Security Controls: Identifying the security measures and controls that are in place to safeguard data, such as
          encryption, access controls, authentication mechanisms, and data loss prevention.
        </li>
        <li className="mb-5">
          Configuration Management: Ensuring that hardware and software systems are configured securely and according to
          best practices to minimize potential vulnerabilities.
        </li>
        <li className="mb-5">
          Compliance: Ensuring that the organization's data security practices align with relevant regulatory standards
          and industry compliance requirements.
        </li>
        <li className="mb-5">
          Continuous Monitoring: Implementing tools and processes to continuously monitor the organization's data
          security posture, detect anomalies, and respond to security incidents promptly.
        </li>
        <li className="mb-5">
          Incident Response: Establishing plans and protocols to address and mitigate security incidents effectively
          when they occur.
        </li>
        <li className="mb-5">
          Policy Enforcement: Ensuring that security policies are well-defined, communicated to all relevant parties,
          and consistently enforced.
        </li>
        <li className="mb-5">
          Auditing and Reporting: Conducting regular audits and generating reports to provide insights into the
          organization's data security posture and its effectiveness.
        </li>
      </ul>

      <p className="mb-12 text-lg text-left">
        DSPM solutions often utilize automation and advanced analytics to streamline the process of assessing and
        managing an organization's data security posture. These solutions can help organizations proactively identify
        weaknesses, prioritize remediation efforts, and maintain a strong security stance in an ever-evolving threat
        landscape.
      </p>

      <p className="mb-12 text-lg text-left">
        Trustle complements your DSPM strategy by helping you classify your data by sensitivity score,revelaving who has
        access to that data, who granted them access to it (for how long, and why). Trustle also detects anomolies and
        misconfigurations in the systems which home the data, making it easy to respond, mitigate, and reduce your
        attack surface.
      </p>
    </div>
  );
};

const PermissionUtilizationRate = () => {
  const dataImages = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: {eq: "bg/rebrand/bg-main-top.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);
  const {placeholderImage} = dataImages;
  const image = getImage(placeholderImage);
  const bgImage = convertToBgImage(image);
  const title = `DSPM`;
  const description = `Data Security Posture Management`;
  return (
    <Layout seo={<Seo title={`Glossary: ${title} | Trustle`} description={description} />}>
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        preserveStackingContext
        className="bg-cover"
      >
        <section className="">
          <div className="flex flex-col pt-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto ">
            <SectionIntro isPageHeader hero="Glossary" title={title} description={`(${description})`} />
          </div>
        </section>

        <section className="pb-8 ">
          <div className="flex flex-col py-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto">
            <div className=" max-w-[1360px] 3xl:max-w-[1420px] mx-auto text-blue">
              <div className="py-16">
                <BackGlossary />
              </div>
              <GlossaryPageContent />
            </div>
          </div>
        </section>
      </BackgroundImage>
      <section className="pb-16 ">
        <BannerRussel
          title="Wondering how Trustle works?"
          description={'Get an inside look at Trustle. Schedule a demo with us today.'}
        />
      </section>
    </Layout>
  );
};

export default PermissionUtilizationRate;
